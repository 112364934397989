import React, { useEffect, useState } from 'react';
import { graphql, navigate, useStaticQuery } from 'gatsby';

import BlogPreviewGrid from '../../components/BlogPreviewGrid';
import Container from '../../components/Container';
import Layout from '../../components/Layout/Layout';
import ThemeLink from '../../components/ThemeLink';
import * as styles from './index.module.css';

const BlogPage = (props) => {
  const [journals, setJournals] = useState([]);
  const journalData = useStaticQuery(graphql`
    query {
      allGoogleBlogsSheet {
        edges {
          node {
            images
            title
            author
            date
            copy
            captions
            slug
            date
          }
        }
      }
    }
  `);

  useEffect(() => {
    setJournals(
      journalData.allGoogleBlogsSheet.edges.map((edge) => {
        return {
          alt: edge.node.title,
          category: 'Greek',
          image: edge.node.images.split(',')?.[0],
          title: edge.node.title,
          link: `${edge.node.slug}`,
          date: edge.node.date,
          author: edge.node.author,
        };
      })
    );
  }, [journalData]);

  return (
    <Layout disablePaddingBottom>
      <div className={styles.root}>
        {/* <Hero
          maxWidth={'400px'}
          image={'/blogCover.png'}
          title={`The new standard of Closing`}
          ctaLink={'read story'}
          ctaTo={'/blog/sample'}
          header={'design'}
        /> */}

        <div className={styles.navContainer}>
          <ThemeLink onClick={() => navigate('/blog')} to={'/blog'}>
            All Posts
          </ThemeLink>
        </div>

        {/* Blog Grid */}
        <div className={styles.blogsContainer}>
          <Container size={'large'}>
            <BlogPreviewGrid data={journals} hideReadMoreOnWeb showExcerpt />
          </Container>
        </div>
      </div>
    </Layout>
  );
};

export default BlogPage;
